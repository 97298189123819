import { Box, Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogProps, Typography } from '@mui/material'

import appstore from "@/assets/images/appstore.png";
import playstore from "@/assets/images/googleplay.png";
import { useAppSelector } from '@/stores';
import { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

type Props = Omit<DialogProps, "onClose"> & {
    onClose?: () => void
}
const APP_STORE = "https://apps.apple.com/fr/app/id1540754027"
const PLAY_STORE = "https://play.google.com/store/apps/details?id=com.lekiosquenumerique.tgvinoui"

export default function DownloadAppDialog(props: Props) {
    const { onClose, open } = props
    const kioskCode = useAppSelector(state => state?.auth?.kioskCode)

    const openAppStore = () => {
        window.location.replace(`com.lekiosquenumerique.tgvinoui://access/${kioskCode}`);

        setTimeout(() => {
            window.location.replace(APP_STORE);
        }, 1000);
    }
    const openPlayStore = () => {
        const url = `intent://sgv-demo.kioskpress.fr/#/access/${kioskCode}#Intent;scheme=https;package=com.lekiosquenumerique.tgvinoui;end`;
        window.location.replace(url);
    }
    useEffect(() => {
        if (open) {
            if (isAndroid) {
                openPlayStore()
            } else if (isIOS) {
                openAppStore()
            }
        }

    }, [open,])
    if (isAndroid || isIOS) {
        return null
    }
    return (
        <Dialog
            // open={openDownloadModal}
            // onClose={() => setOpenDownloadModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"md"}
            onClose={onClose}
            {...props}
        >
            {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
            <DialogContent>
                <Box id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                    <Typography variant='h5'  >
                        Téléchargez l'application <br />
                        <strong>Le Kiosque Salons TGV INOUI</strong><br />
                        et emportez vos magazines partout où vous êtes
                    </Typography>
                    <p>
                        {/* <a href="https://apps.apple.com/fr/app/id1540754027"> */}
                        <ButtonBase onClick={openAppStore}>
                            <img
                                src={appstore}
                                alt="App store"
                                className="img img-fluid"
                                style={{ width: '190px' }}
                            />
                        </ButtonBase>
                        {/* <a href="https://play.google.com/store/apps/details?id=com.lekiosquenumerique.tgvinoui"> */}
                        <ButtonBase onClick={openPlayStore}>
                            <img
                                src={playstore}
                                alt="Play Store"
                                className="img img-fluid"
                                style={{ width: '200px' }}
                            />
                        </ButtonBase>
                    </p>
                    {/* <p>
                            <Button onClick={() => onPressIssue(publicationNumber!)} >
                                Continuer la lecture en ligne en illimité
                            </Button>
                        </p> */}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    )
}

