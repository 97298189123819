import IssueItem from '@/components/IssueItem';
import { useCategoriesQuery } from '@/stores/api';
import { Issue } from '@/stores/types';
import { generateToken } from '@/utils/reader';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, Theme, useMediaQuery } from '@mui/material';

import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';

import appstore from "@/assets/images/appstore.png";
import playstore from "@/assets/images/googleplay.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DownloadAppDialog from '@/components/DownloadAppDialog';

interface Props {
}

export default function Home(props: Props) {

    const { data: categories, isLoading } = useCategoriesQuery()
    const [openDownloadModal, setOpenDownloadModal] = useState(false)

    const Loading = useMemo(() => {
        if (isLoading) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <CircularProgress color='primary' />
                </Box>
            )
        }
        return null;
    }, [isLoading])

    const onPressIssue = (issue: Issue) => () => {
        const jwt = generateToken(issue)
        window.location.href = `${process.env.REACT_APP_READER_URL}${jwt}`
    }

    const responsive = {

        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 2,
        },
    };
    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    return (
        <Container maxWidth={"lg"} sx={{ flex: 1, }}>
            <Typography variant="h6" mb={2} sx={{ textAlign: 'center' }}>
                BIENVENUE SUR VOTRE KIOSQUE NUMERIQUE
            </Typography>
            {Loading}

            {
                categories?.map(category => {
                    return (
                        <Box key={category.id} >
                            <Typography variant={"h4"} sx={{ my: 2 }}>{category.name}</Typography>
                            {hidden ? <Carousel
                                responsive={responsive}
                                draggable={false}
                            >
                                {category.items.map((item, i) => {
                                    const { publicationNumber } = item;
                                    return (
                                        <Box key={i} sx={{ mr: 1, py: 2, }}>

                                            <IssueItem
                                                title={item.name}
                                                image={publicationNumber?.coverUrl!}
                                                publishedAt={publicationNumber?.dateParution}
                                                onClick={onPressIssue(publicationNumber!)}
                                                onDownload={() => setOpenDownloadModal(true)}
                                                key={i}
                                                sx={{ mr: 3 }}
                                            />
                                        </Box>
                                    )
                                })}
                            </Carousel> : (
                                <Grid
                                    mt={3}
                                    container
                                    columnSpacing={2}
                                    rowSpacing={3}
                                >
                                    {category.items.map((item, i) => {
                                        const { publicationNumber } = item;
                                        return (
                                            <Grid key={i} item xs={6} sm={3} md={2} >

                                                <IssueItem
                                                    title={item.name}
                                                    image={publicationNumber?.coverUrl!}
                                                    publishedAt={publicationNumber?.dateParution}
                                                    onClick={onPressIssue(publicationNumber!)}
                                                    onDownload={() => setOpenDownloadModal(true)}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )}


                        </Box>
                    )
                    // return (
                    //     <Box key={category.id}>
                    //         <Typography variant={"h4"} sx={{ my: 2 }}>{category.name}</Typography>

                    //         <Grid
                    //             container
                    //             columnSpacing={2}
                    //             rowSpacing={3}
                    //             direction="row"
                    //             justifyContent="flex-start"
                    //             alignItems="stretch">
                    //             {category.items.map((item, i) => {
                    //                 const { publicationNumber } = item;
                    //                 return (
                    //                     <Grid key={i} item xs={6} sm={3} md={2} >

                    //                         <IssueItem
                    //                             title={item.name}
                    //                             image={publicationNumber?.coverUrl!}
                    //                             publishedAt={publicationNumber?.dateParution}
                    //                             onClick={onPressIssue(publicationNumber!)}
                    //                             onDownload={() => setOpenDownloadModal(true)}
                    //                         />
                    //                     </Grid>
                    //                 )
                    //             })}
                    //         </Grid>
                    //     </Box>
                    // )
                })
            }
            <DownloadAppDialog
                open={openDownloadModal}
                onClose={() => setOpenDownloadModal(false)}
            />
        </Container >
    );
}